import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Layout from "../components/layoutEaster"
import BgImage from "../images/Easter2024_Big.jpg"
import Easter from "../images/easter_2025_square.jpg"
import { Button, Collapse, Stack, Box } from "@chakra-ui/core"
import SEO from "../components/seo"
import PageHeaders from "../components/pageHeaders"
import EasterHead from "../components/EasterHead"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
`

function IndexPage() {
  const [show, setShow] = React.useState(false)
  const [show1, setShow1] = React.useState(false)
  const [show2, setShow2] = React.useState(false)

  const handleToggle1 = () => setShow1(!show1)
  const handleToggle = () => setShow(!show)
  const handleToggle2 = () => setShow2(!show2)
  {
    return (
      <Layout
        ImgSrc={BgImage}
        MainText="Easter at Cross Church"
        SecondText="Saturday and Sunday, April 19th & 20th"
      >
        <EasterHead />
        <SEO title="Easter at Cross Church" />
        <PageHeaders
          css={css`
            display: flex;
            margin-top: 4rem;
          `}
        >
          Easter at Cross Church
        </PageHeaders>
        <HeaderText></HeaderText>
        <div
          css={css`
            display: flex;
            @media (max-width: 940px) {
              flex-direction: column;
            }
          `}
        >
          <div id="times">
            <ParagraphText>
              This Easter, Cross Church is offering 10 services across 4
              different campuses. Join us live in-person at{" "}
              <Link to="/surprise">Cross Church Surprise</Link>,{" "}
              <Link to="/cornville">Cross Church Cornville</Link>,{" "}
              <Link to="/phoenix">Cross Church Phoenix</Link>, or{" "}
              <Link to="/elmirage">Cross Church El Mirage</Link>.
            </ParagraphText>
            <HeaderText>Surprise Campus</HeaderText>
            <ParagraphText>
              April 19th @ 5:00pm & April 20th at 9:00am or 10:30am
              <br />
            </ParagraphText>
            <HeaderText>Phoenix Campus</HeaderText>
            <ParagraphText>
              April 20th @ 9:00 or 10:30am
              <br />
            </ParagraphText>
            <HeaderText>Cornville Campus</HeaderText>
            <ParagraphText>
              April 20th @ 9:00 or 10:30am
              <br />
            </ParagraphText>
            <HeaderText>El Mirage Campus</HeaderText>
            <ParagraphText>
              April 20th @ 8:30, 10:00, or 11:30am
              <br />
            </ParagraphText>
          </div>
          <img
            src={Easter}
            alt="Easter at Cross Church"
            css={css`
              width: auto;
              height: 400px;
              margin-left: 4rem;
              border-radius: 6px;
              @media (max-width: 940px) {
                width: 100%;
                margin-top: 2rem;
                margin-left: 0;
                height: auto;
              }
            `}
          />
        </div>
        <ParagraphText
          css={css`
            margin-top: 4rem;
          `}
        >
          <HeaderText>
            Invite your friends and family to join us for Easter at Cross
            Church!
          </HeaderText>
        </ParagraphText>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 2rem;
          `}
        >
          <a
            css={css`
              width: 100%;
              ${"" /* styled like a button */}
              background-color: #333333;
              color: white;
              padding: 1rem;
              border-radius: 4px;
              text-align: center;
              text-decoration: none;
              margin: 1rem 0;
              ${"" /* Hover styles */}
              &:hover {
                background-color: #4a4a4a;
                color: white;
              }
            `}
            href="sms:?&body=I'm%20attending%20Easter%20at%20Cross%20Church%20on%20April%2020th.%0AYou%20should%20come%20with%20me!%0Ahttps://crosschurchcares.com/easter"
          >
            Text a Friend
          </a>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
